<template>
  <section>
    <div class="d-flex justify-content-between mt-5">
      <div class="">
        <span class="f16 fw-bold">{{ $t("Activity status") }}</span>
      </div>
      <div>
        <v-select
          :items="option"
          mandatory
          v-model="selected"
          solo
          dense
          class="rounded-pill fit f12"
          hide-details
        >
        </v-select>
      </div>
    </div>
    <div>
      <div class="mt-2">
        <Bar
          :chart-options="chartOptions"
          :chart-data="chartData"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
          :height="height"
        />
      </div>
    </div>

    <div class="d-flex align-items-center my-8" v-for="(member, index) in members" :key="index">
          <v-avatar size="64" rounded="circle">
            <v-img :lazy-src="require('@/assets/images/profile-circle.png')" v-if="member.pic" :src="member.pic" />
            <v-img v-else :src="require('@/assets/images/profile.jpg')" />
          </v-avatar>
          <div class="ms-4 me-0">
            <span class="d-block f14 font-weight-bold">
              {{ member.doctorFullName }}
            </span>
            <span class="f13 grey--text text--darken-1">{{ member.roleName }}</span> |
            <span class="f13 green--text text--darken-1">{{ member.questionCount }}</span>
          </div>
        </div>
  </section>
</template>

<script>
import apiService from "@/http/ApiService";
import moment from 'moment-jalaali';
import fa from "moment/locale/fa";
moment.locale("fa", fa);
moment.loadPersian({ dialect: 'persian-modern' });

import createNumberMask from 'text-mask-addons/dist/createNumberMask';

  const currencyMask = createNumberMask({
    prefix: '',
    allowDecimal: false,
    includeThousandsSeparator: true,
    allowNegative: false,
  });

import { Bar } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  LinearScale,
  CategoryScale,
  PointElement,
  Filler,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  Filler,
  BarElement,
  LinearScale,
  CategoryScale,
  PointElement
);

export default {
  components: {
    Bar,
  },
  props: {
    chartId: {
      type: String,
      default: "bar-chart'",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      type: String,
      default: "",
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data(){
    return {
      currencyMask,

      clinicId: null,

      members: [],
      chartLables: [],
      chartValues:[],

      option: [],
      selected: 1,
    }
  },
  computed: {
    chartData() { 
      return {
        labels: this.chartLables,
        datasets: [
          {
            label: this.$t("Operation report"),
            backgroundColor: ["#20B1EA"],
            data: this.chartValues,
            borderWidth: 1,
            hoverBorderWidth: 0,
            barPercentage: 0.8,
            fill: {
                target: 'origin',
                above: '#2196F325',
                below: '#EF535025'
            },
          },
        ],
      }
    },
    chartOptions() { 
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            suggestedMin: 0,
            ticks: {
              stepSize: 1,
              display: true,
              font: {
                size: 11,
                family: "IRANSans",
              },
            },
          },

          x: {
            ticks: {
              minRotation: 45,
              font: {
                size: 11,
                family: "IRANSans",
              },
            },
          },
        },
        plugins: {
          legend: {
            display: true,
            labels: {
              font: {
                size: 13,
                family: "IRANSans",
              },
            },
          },
          tooltip: {
            enabled: true,
            titleFont: {
              size: 10,
              family: "IRANSans",
            },
            bodyFont: {
              size: 11,
              family: "IRANSans",
            },

            footerFont: {
              size: 12,
              family: "IRANSans",
            },
            callbacks: {
              label: function (context) {
                let label = context.dataset.label || "0";
                if (context.parsed.y !== null) {
                  label = " " + context.parsed.y + " ";
                }
                return label;
              },
            },
          },
        },
      }
    },
  },
  methods: {
    getReportOfPeriod(startDate, endDate){
      var startMoment = moment(startDate, 'jYYYY-jMM-jDD')
      var endMoment = moment(endDate, 'jYYYY-jMM-jDD')
      
      var start = startMoment.format('YYYY-MM-DD')
      var end = endMoment.format('YYYY-MM-DD')
            
      this.chartLables = [];
      this.chartValues = [];

      apiService.getQuestionSumReport(start, end, this.clinicId)
        .then((response) => {
          this.chartLables = [];
          this.chartValues = [];

          this.members = response.data;
          this.initChart(this.members);
        })
        .catch((err) => {
          console.log(err)
          this.$router.go(-1);
        })
    },
    initChart(members){
      members.forEach(element => {
      this.chartLables.push(element.doctorFullName);
        if(element.questionCount && element.questionCount != "null" && element.questionCount > 0) {
          this.chartValues.push(element.questionCount);
        }
        else {
          this.chartValues.push(0);          
        }
      });
    }
  },
  beforeMount() {
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);
  },
  mounted(){
    this.clinicId = this.$route.params.clinicId;

    var start = new moment().jYear() + "-01-01"
    var end = new moment().jYear() + "-01-31"
    this.getReportOfPeriod(start, end)

    var list = ["فروردین", "اردیبهشت", "خرداد", "تیر", "مرداد", "شهریور", "مهر", "آبان", "آذر", "دی", "بهمن", "اسفند"];
    for (var i = 0; i < 12; i++) {
      var month = {
        value: i,
        text: list[i]
      }
      this.option.push(month);
    }
    this.selected = new moment().jMonth();

    // console.log(new moment().jYear());
  },
  watch: {
    selected: function () {
      var start = '';
      var end = '';
      switch (this.selected) {
        case 0: { //farvardin
          start = new moment().jYear() + "-01-01"
          end = new moment().jYear() + "-01-31"
          this.getReportOfPeriod(start, end)

          break;
        }
        
        case 1: { //ordibehesht
          start = new moment().jYear() + "-02-01"
          end = new moment().jYear() + "-02-31"
          this.getReportOfPeriod(start, end)
          break;
        }
        
        case 2: { //khordad
          start = new moment().jYear() + "-03-01"
          end = new moment().jYear() + "-03-31"
          this.getReportOfPeriod(start, end)
          break;
        }
        
        case 3: { //tir
          start = new moment().jYear() + "-04-01"
          end = new moment().jYear() + "-04-31"
          this.getReportOfPeriod(start, end)
          break;
        }
        
        case 4: { //mordad
          start = new moment().jYear() + "-05-01"
          end = new moment().jYear() + "-05-31"
          this.getReportOfPeriod(start, end)
          break;
        }
        
        case 5: { //shahrivar
          start = new moment().jYear() + "-06-01"
          end = new moment().jYear() + "-06-31"
          this.getReportOfPeriod(start, end)
          break;
        }
        
        case 6: { //mehr
          start = new moment().jYear() + "-07-01"
          end = new moment().jYear() + "-07-30"
          this.getReportOfPeriod(start, end)
          break;
        }
        
        case 7: { //aban
          start = new moment().jYear() + "-08-01"
          end = new moment().jYear() + "-08-30"
          this.getReportOfPeriod(start, end)
          break;
        }
        
        case 8: { //azar
          start = new moment().jYear() + "-09-01"
          end = new moment().jYear() + "-09-30"
          this.getReportOfPeriod(start, end)
          break;
        }
        
        case 9: { //dey
          start = new moment().jYear() + "-10-01"
          end = new moment().jYear() + "-10-30"
          this.getReportOfPeriod(start, end)
          break;
        }
        
        case 10: { //bahman
          start = new moment().jYear() + "-11-01"
          end = new moment().jYear() + "-11-30"
          this.getReportOfPeriod(start, end)
          break;
        }
        
        case 11: { //esfand
          start = new moment().jYear() + "-12-01"
          if(moment.jIsLeapYear(new moment().jYear())) {
            end = new moment().jYear() + "-12-30"
          }
          else {
            end = new moment().jYear() + "-12-29"
          }
          this.getReportOfPeriod(start, end)
          break;
        }
        

      
      }
    },
  },
}
</script>
